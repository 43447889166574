import React from "react";

import Frame from "~/interface/layout/frame";
import SEO from "~/helpers/seo";
import Navigation from "~/interface/compositions/navigation";
import Footer from "~/interface/compositions/footer";
import Map from "~/interface/compositions/map";

const ContactPage = () => {
  return (
    <Frame className="bg-black text-white">
      <div className="h-[calc(100vh-170px)] md:flex justify-between">
        <div className="flex flex-col md:flex-row gap-10 sm:gap-28 h-full px-10 sm:px-20 md:px-[128px]">
          <Navigation />

          <div className="flex items-center">
            <div className="max-w-[600px]">
              <h1 className="mb-5 text-xl font-semibold text-white">
                お問い合わせ
              </h1>
              <h1 className="font-regular tracking-[0.02em] leading-[32px]">
                Webメディア「unprinted」に関するお問い合わせや、
                デジタルプロダクト戦略のコンサルティングに関するご相談は「
                <a
                  href="mailto:enquiry@artefact.co.jp"
                  className="text-lg font-bold"
                >
                  enquiry@artefact.co.jp
                </a>
                」 にて承っております 。
              </h1>
            </div>
          </div>
        </div>

        <div className="hidden lg:block bg-gray-darkest h-full w-1/5 relative border-l-[0.5px] border-white">
          <Map />
        </div>
      </div>

      <Footer />
    </Frame>
  );
};

export default ContactPage;

export const Head = () => {
  return (
    <SEO
      title="お問い合わせ | 株式会社Artefact"
      description="Artefactの運営するWebマガジン「unprinted」に関するお問い合わせや、デジタルプロダクトの戦略、UI/UXに関するご相談はこちら。まずはメールにてお気軽にお問い合わせください。"
      url="/contact/"
    />
  );
};
